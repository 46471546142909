import React from "react"
import { Box, Text, SimpleGrid, Flex, Heading, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Feature1Image from "@/assets/images/userresearch/signup/feature-1.png"
import Feature2Image from "@/assets/images/userresearch/signup/feature-2.png"
import { headerHeight } from "@/utils/userresearch/constant"

const features = [
  { image: Feature1Image },
  { image: Feature2Image },
]

const SignUp = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      id="SignUp"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
      position="relative"
      backgroundColor="#F5F5F5"
      padding="20px"
    >
     <Heading size="2xl" textAlign="center" fontSize="32px" fontWeight="600" color="#003686" padding="30px">
          {t(`home-page.sign-up.heading`)}
      </Heading>
      <Box mx="auto" maxW="3xl" >
        <SimpleGrid
          px="6"
          py="20"
          columns={{ base: 1, md: 2 }}
          spacing={{ base: "16", md: "12" }}
          textAlign="center"
          pt={{ base: "0px", md: "20px" }}
          fontFamily="Noto Sans"
        >
          {features.map(({ image }, index) => (
            <Flex
              direction="column"
              backgroundColor="#FFFFFF"
              key={`feature-${index + 1}`}
              alignItems="center"
              px="4"
              height="120%"
              borderRadius="8px"
            >
              <Image
                boxSize="56px"
                src={image}
                alt={`${t(
                  `home-page.sign-up.features.${index}.name`
                )} logo`}
                mb="35"
                mt="35"
              />

              <Heading fontSize="20px" fontWeight="bold" mb="6" size="lg" color="#000000" lineHeight="32px" fontFamily="Noto Sans" fontStyle="normal">
                {t(`home-page.sign-up.features.${index}.name`)}
              </Heading>

              <Text fontSize="16px" fontWeight="400" lineHeight="26px" fontFamily="Noto Sans" fontStyle="normal">
                {t(
                  `home-page.sign-up.features.${index}.description`
                )}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default SignUp
